import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { SetupOrganizationProfile } from "../components/SetupOrganizationProfile";
import { PersonalInfo } from "../components/PersonalInfo";
import axios from "axios";

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

const {
  REACT_APP_APPSUMO_HOST,
  REACT_APP_APPSUMO_CLIENT_ID,
  REACT_APP_APPSUMO_CLIENT_SECRET,
  REACT_APP_APPSUMO_REDIRECT_URI,
} = process.env;

export function OnboardingLayout() {
  const [currentForm, setCurrentForm] = useState(
    FormType.SetupOrganizationProfile,
  );

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const getToken = async () => {
      let code = searchParams.get("code");

      const tokenResponse = await axios({
        method: "POST",
        url: "https://appsumo.com/openid/token/",
        headers: {
          "Content-type": "application/json",
        },
        data: {
          client_id: REACT_APP_APPSUMO_CLIENT_ID,
          client_secret: REACT_APP_APPSUMO_CLIENT_SECRET,
          code: code,
          redirect_uri: REACT_APP_APPSUMO_REDIRECT_URI,
          grant_type: "authorization_code",
        },
      });

      let { access_token, refresh_token } = tokenResponse.data;

      // Function to fetch license information
      const fetchLicenseInfo = async (accessToken: string) => {
        try {
          // Use the access token to fetch license information
          const licenseResponse = await axios.get(
            "https://api.licensing.appsumo.com/v2/licenses/license-key/events", // Update this URL to the correct endpoint
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          

          const [licenseInfo] = licenseResponse.data.filter(
            (item: any) => item.event_type === "purchase",
          ).limit(1);
          
          // Store license information as needed
          setFormData((prevData) => ({
            ...prevData,
            app_sumo_code: licenseInfo.license_key,
          }));
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
            // Access token expired, use refresh token to get a new access token
            const refreshTokenResponse = await axios({
              method: "POST",
              url: "https://appsumo.com/openid/token/",
              headers: {
                "Content-type": "application/json",
              },
              data: {
                client_id: REACT_APP_APPSUMO_CLIENT_ID,
                client_secret: REACT_APP_APPSUMO_CLIENT_SECRET,
                refresh_token: refresh_token,
                grant_type: "refresh_token",
              },
            });

            const {
              access_token: new_access_token,
              refresh_token: new_refresh_token,
            } = refreshTokenResponse.data;

            // Update tokens
            access_token = new_access_token;
            refresh_token = new_refresh_token;

            // Retry fetching the license info with new access token
            await fetchLicenseInfo(access_token);
          } else {
            // Handle other errors
            console.error("Failed to fetch license information", error);
          }
        }
      };

      // Fetch license information
      await fetchLicenseInfo(access_token);
    };

    getToken().then((response) => {
      console.log({ response });
    });
  }, []);

  const [formData, setFormData] = useState({
    website: "",
    company_name: "",
    company_size: "",
    app_sumo_code: "",
    name: "",
    last_name: "",
    email_address: "",
    country: "",
    role: "",
    timezone: "",
    subdomain: "",
    invoce_email_address: "",
    logo: "",
    working_options: "",
    unpaid_leave: "",
    paid_holiday: "",
    paid_seek_leave_per_year: "",
    remote_working_per_week: "",
  });

  const setCurrentFormData = (data: any) => {
    setFormData(data);
  };

  useEffect(() => {
    console.log(formData, "formData");
  }, [formData]);

  const renderForm = () => {
    switch (currentForm) {
      case FormType.SetupOrganizationProfile:
        return (
          <SetupOrganizationProfile
            setCurrentForm={setCurrentForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      case FormType.PersonalInformation:
        return (
          <PersonalInfo
            setCurrentFormProps={setCurrentForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      default:
        return null;
    }
  };

  return <div className="min-h-full min-w-full bg-white">{renderForm()}</div>;
}
