import { useDropzone } from "react-dropzone";

import img from "../../../assets/upload.png";
import fileIcon from "../../../assets/file_icon.svg";
import refresh from "../../../assets/refresh.svg";
import trash from "../../../assets/trash.svg";

type FileUploadComponentProps = {
  onDrop: (acceptedFiles: File[]) => void;
  fileName?: string;
  isLoading?: boolean;
  onRemove: () => void;
  fileSize: string;
  titleSmall?: boolean;
};

export function FileUploadComponent({
  onDrop,
  fileName,
  isLoading,
  onRemove,
  fileSize,
  titleSmall,
}: FileUploadComponentProps) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <>
      {!fileName ? (
        <>
          <div
            {...getRootProps({
              className:
                "border-2 border-dashed border-[#FDF0DE] rounded-md text-center transition ease-in-out duration-200 mt-6 pt-16 pb-16 hover:border-gray-400 relative w-full",
            })}
          >
            <input {...getInputProps()} />

            <div
              className="absolute inset-0 flex w-full flex-col items-center justify-center"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img src={img} alt="upload-icon" />

              {titleSmall ? (
                <p className="mt-2">
                  <span className="text-[14px] font-semibold">
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </p>
              ) : (
                <>
                  <p>
                    <span className="text-[14px] font-semibold">
                      Click to upload
                    </span>{" "}
                    or drag and drop
                  </p>
                  <p className="text-[14px]">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </p>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="mt-4 flex items-start justify-between">
          <div className="flex">
            <img src={fileIcon} alt="file-icon" className="h-full" />
            <div className="ml-2 flex flex-col">
              <p className="text-[14px] font-semibold">{fileName}</p>
              <p className="text-[14px]">{fileSize} – 100% uploaded</p>
            </div>
          </div>

          <div className="flex items-center">
            <img
              src={refresh}
              alt="refresh-icon"
              className="mr-2 h-5 w-5 cursor-pointer"
            />
            <img
              src={trash}
              alt="trash-icon"
              className="h-5 w-5 cursor-pointer"
              onClick={() => onRemove()}
            />
          </div>
        </div>
      )}
    </>
  );
}
