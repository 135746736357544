import React from "react";

type StepIndicatorProps = {
  currentStep: number;
  totalSteps: number;
};

const StepIndicator: React.FC<StepIndicatorProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div className="flex justify-center mt-2">
      {[...Array(totalSteps)].map((_, index) => (
        <div key={index} className="flex items-center">
          <div
            className={`flex items-center justify-center h-8 w-8 rounded-full font-semibold ${
              currentStep === index + 1
                ? "bg-orange-500 text-white"
                : currentStep > index + 1
                  ? "bg-orange-200 text-orange-500"
                  : "bg-gray-200 text-gray-500"
            }`}
          >
            {currentStep > index + 1 ? "✔" : index + 1}
          </div>
          {index < totalSteps - 1 && (
            <div className="flex items-center">
              <div className="flex-1 h-px bg-gray-200 ml-1 mr-1"></div>
              <div
                className={`w-12 h-px ${currentStep > index + 1 ? "bg-orange-200" : "bg-gray-200"}`}
              ></div>
              <div className="flex-1 h-px bg-gray-200 ml-1 mr-1"></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;
