import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { ALLOWANCE, WORKING_OPTIONS } from "../../../constants";
import { Input } from "../../../ui/form/Input";
import { Select } from "../../../ui/form/Select";

import StepIndicator from "../../StepIndicator";
import { OnboardingFormData } from "../../../types";
import logo from "../../../assets/redstone-icon.svg";

enum PersonalInfoFormType {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
}

type SelectedOption = {
  title: string;
  value: FormikKeys;
};

type FormikKeys =
  | "working_options"
  | "unpaid_leave"
  | "paid_holiday"
  | "paid_seek_leave_per_year"
  | "remote_working_per_week"
  | "allowance";

type Props = {
  setCurrentFormData: (data: Partial<OnboardingFormData>) => void;
  formData: OnboardingFormData;
  setCurrentForm: (data: PersonalInfoFormType) => void;
};

export function ThirdStep({
  setCurrentFormData,
  setCurrentForm,
  formData,
}: Props) {
  const [selectedAllowances, setSelectedAllowances] = useState<
    SelectedOption[]
  >([]);
  const [isFormClear, setIsFormClear] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      working_options: formData.working_options || "",
      allowance: "",
      unpaid_leave: formData.unpaid_leave || "",
      paid_holiday: formData.paid_holiday || "",
      paid_seek_leave_per_year: formData.paid_seek_leave_per_year || "",
      remote_working_per_week: formData.remote_working_per_week || "",
    },
    validationSchema: yup.object({
      working_options: yup.string().required(),
      allowance: yup.string(),
      unpaid_leave: yup.string(),
      paid_holiday: yup.string(),
      paid_seek_leave_per_year: yup.string(),
      remote_working_per_week: yup.string(),
    }),
    onSubmit: (data) => {
      console.log(data, "Submit data");
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    formik.handleSubmit(event);

    if (isFormClear) {
      setCurrentForm(PersonalInfoFormType.FourthStep);
    }
  };

  useEffect(() => {
    console.log(formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;

    if (isFormValid) {
      const updatedValues = Object.fromEntries(
        Object.entries(formik.values).filter(([key, value]) => value !== ""),
      );

      const updatedFormData = { ...formData, ...updatedValues };
      setCurrentFormData(updatedFormData);
      setIsFormClear(true);
    }
  }, [formik.errors, formik.values]);

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  useEffect(() => {
    const allowanceKeys: FormikKeys[] = [
      "unpaid_leave",
      "paid_holiday",
      "paid_seek_leave_per_year",
      "remote_working_per_week",
    ];

    const newSelectedAllowances: SelectedOption[] = [];
    allowanceKeys.forEach((key) => {
      //@ts-ignore
      if (formData[key]) {
        const allowance = ALLOWANCE.find((a) => a.value === key);
        if (allowance) {
          newSelectedAllowances.push({ title: allowance.title, value: key });
        }
      }
    });
    setSelectedAllowances(newSelectedAllowances);
  }, [formData]);

  const handleAllowanceChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedValue = event.target.value;
    const selectedAllowance = ALLOWANCE.find(
      (allowance: any) => allowance.title === selectedValue,
    );

    if (!selectedAllowance) {
      return;
    }

    setSelectedAllowances((prevSelectedAllowances) => [
      ...prevSelectedAllowances,
      {
        title: selectedAllowance.title,
        value: selectedAllowance.value as FormikKeys,
      },
    ]);
  };

  return (
    <div className="relative flex items-center justify-center">
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="flex h-16 w-16 items-center justify-center rounded-full font-semibold">
            <img src={logo} alt="Redstone" />
          </div>
        </div>
        <h1
          className="mt-8 text-4xl font-semibold text-gray-900"
          style={{ color: "#0A1019" }}
        >
          Leave Settings
        </h1>

        <p
          className="mt-4 text-center text-base text-gray-700"
          style={{ color: "#555A61", fontSize: "16px" }}
        >
          Please follow these 4 easy steps to create your
          <br /> company profile
        </p>

        <StepIndicator currentStep={3} totalSteps={3} />
        <form className="mt-2 w-full" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:mt-0">
            <label
              htmlFor="working_options"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Working options
            </label>
            <Select
              id="working_options"
              name="working_options"
              value={formik.values.working_options}
              onChange={formik.handleChange}
            >
              {WORKING_OPTIONS.map(({ title }: any, index: number) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </Select>

            {findError("working_options") && (
              <p className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
                {findError("working_options")}
              </p>
            )}
          </div>

          {ALLOWANCE.length !== selectedAllowances.length && (
            <div className="mt-4 flex flex-col sm:mt-0">
              <label
                htmlFor="allowance"
                className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Allowance
              </label>
              <Select
                id="allowance"
                name="allowance"
                defaultValue=""
                onChange={handleAllowanceChange}
                value={
                  selectedAllowances.length > 0
                    ? ""
                    : formik.values.allowance || ""
                }
              >
                <option value="" disabled hidden>
                  Select an allowance
                </option>
                {ALLOWANCE.filter(
                  ({ title }: any) =>
                    !selectedAllowances.some(
                      (option) => option.title === title,
                    ),
                ).map(({ title }: any, index: number) => (
                  <option key={index} value={title}>
                    {title}
                  </option>
                ))}
              </Select>

              {findError("allowance") && (
                <p className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
                  {findError("allowance")}
                </p>
              )}
            </div>
          )}

          {selectedAllowances.map((selectedValue) => (
            <div
              key={selectedValue.value}
              className="mt-4 flex flex-col sm:mt-0"
            >
              <label
                htmlFor={selectedValue.value}
                className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                {selectedValue.title}
              </label>
              <Input
                type="number"
                placeholder={`Enter ${selectedValue.title}`}
                id={selectedValue.value}
                name={selectedValue.value}
                error={findError(selectedValue.value)}
                value={formik.values[selectedValue.value] || ""}
                onChange={formik.handleChange}
              />
            </div>
          ))}

          <div className="mb-4 mt-4 flex">
            <button
              onClick={() => setCurrentForm(PersonalInfoFormType.SecondStep)}
              className="w-1/2 rounded-md border border-solid border-gray-300 bg-white pb-1 pt-1 font-semibold text-gray-900 focus:outline-none"
            >
              Back
            </button>
            <button
              className="focus:shadow-outline ml-4 w-1/2  rounded bg-red-600 font-bold text-white hover:bg-red-700 focus:outline-none sm:mb-0"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
