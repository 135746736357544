import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { TIMEZONES } from "../../../constants";
import { Input } from "../../../ui/form/Input";
import { Select } from "../../../ui/form/Select";

import StepIndicator from "../../StepIndicator";
import { FileUploadComponent } from "../FileUploadComponent";
import { OnboardingFormData } from "../../../types";

import logo from "../../../assets/redstone-icon.svg";

enum PersonalInfoFormType {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
}

type Props = {
  setCurrentFormData: (data: Partial<OnboardingFormData>) => void;
  formData: OnboardingFormData;
  setCurrentForm: (data: PersonalInfoFormType) => void;
};

export function SecondStep({
  setCurrentFormData,
  setCurrentForm,
  formData,
}: Props) {
  const [fileName, setFileName] = useState<string>("");
  const [fileSize, setFileSize] = useState<string>("");
  const [isFormClear, setIsFormClear] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      timezone: formData.timezone || "",
      subdomain: formData.subdomain || "",
      invoce_email_address: formData.invoce_email_address || "",
    },
    validationSchema: yup.object({
      timezone: yup.string().required(),
      subdomain: yup.string().required(),
      invoce_email_address: yup.string().required(),
    }),
    onSubmit: (data) => {
      console.log(data, "Submit data");
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    formik.handleSubmit(event);

    if (isFormClear) {
      setCurrentForm(PersonalInfoFormType.ThirdStep);
    }
  };

  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;

    const isFormFilled = Object.values(formik.values).every(
      (value) => value !== "",
    );

    if (isFormValid && isFormFilled) {
      const updatedValues = Object.fromEntries(
        Object.entries(formik.values).filter(([key, value]) => value !== ""),
      );

      const updatedFormData = { ...formData, ...updatedValues };
      setCurrentFormData(updatedFormData);
      setIsFormClear(true);
    }
  }, [formik.errors, formik.values]);

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  const handleDrop = async (acceptedFiles: File[]) => {
    formik.setFieldValue("logo", acceptedFiles[0]);
    const fileSizeInBytes = acceptedFiles[0].size;
    const fileSizeInKB = fileSizeInBytes / 1024;
    const formattedFileSize = fileSizeInKB.toFixed(2) + " KB";
    setFileSize(formattedFileSize);
    setFileName(acceptedFiles[0].name);
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFileSize("");
  };

  return (
    <div className="relative flex items-center justify-center">
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="flex h-16 w-16 items-center justify-center rounded-full font-semibold">
            <img src={logo} alt="Redstone" />
          </div>
        </div>
        <h1
          className="mt-8 text-4xl font-semibold text-gray-900"
          style={{ color: "#0A1019" }}
        >
          Deployment Settings
        </h1>

        <p
          className="mt-4 text-center text-base text-gray-700"
          style={{ color: "#555A61", fontSize: "16px" }}
        >
          Please follow these 4 easy steps to create your
          <br /> company profile
        </p>

        <StepIndicator currentStep={2} totalSteps={3} />
        <form className="mt-2 w-full" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:mt-0">
            <label
              htmlFor="timezone"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Time Zone
            </label>
            <Select
              id="timezone"
              name="timezone"
              value={formik.values.timezone}
              onChange={formik.handleChange}
            >
              {TIMEZONES.map(({ title }, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </Select>
            {findError("timezone") && (
              <p className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
                {findError("timezone")}
              </p>
            )}
          </div>

          <div className="mt-4 flex flex-col sm:mt-0">
            <label
              htmlFor="subdomain"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Subdomain
            </label>
            <div className="flex">
              <input
                type="text"
                placeholder="Enter subdomain"
                id="subdomain"
                name="subdomain"
                className="block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-sm font-normal text-gray-700 transition ease-in-out focus:border-red-600 focus:outline-none focus:ring-1 focus:ring-red-400"
                value={formik.values.subdomain}
                onChange={formik.handleChange}
              />

              <input
                type="text"
                value="redstone.io"
                readOnly
                title=""
                className="w-20 rounded-r-md border border-l-0 border-gray-300 bg-gray-100 p-2 text-gray-500 focus:border-gray-300 focus:ring-gray-400"
              />
            </div>

            {findError("subdomain") && (
              <p className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
                {findError("subdomain")}
              </p>
            )}
          </div>

          <div className="mt-4 flex flex-col sm:mt-0">
            <label
              htmlFor="invoce_email_address"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Invoice Email Address
            </label>
            <Input
              type="text"
              placeholder="Enter email address"
              id="invoce_email_address"
              name="invoce_email_address"
              error={findError("invoce_email_address")}
              value={formik.values.invoce_email_address}
              onChange={formik.handleChange}
            />
          </div>

          <>
            <FileUploadComponent
              onDrop={handleDrop}
              fileName={fileName}
              onRemove={handleRemoveFile}
              fileSize={fileSize}
            />
          </>

          <div className="mb-4 mt-4 flex">
            <button
              onClick={() => setCurrentForm(PersonalInfoFormType.FirstStep)}
              className="w-1/2 rounded-md border border-solid border-gray-300 bg-white pb-1 pt-1 font-semibold text-gray-900 focus:outline-none"
            >
              Back
            </button>
            <button
              className="focus:shadow-outline ml-4 w-1/2  rounded bg-red-600 font-bold text-white hover:bg-red-700 focus:outline-none sm:mb-0"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
