import img from "../../../../assets/event.svg";

enum PersonalInfoFormType {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  FinalStep,
}

type Props = {
  setCurrentForm: (data: PersonalInfoFormType) => void;
};

export function ChooseOptionComponent({ setCurrentForm }: Props) {
  return (
    <div className="flex w-full flex-col">
      <div className="w-full  text-start">
        <p className="mt-8 text-[18px]">You haven't added any allowances</p>
      </div>

      <img src={img} alt="add allowances" />

      <div className="mb-4 mt-4 flex w-full">
        <button
          onClick={() => setCurrentForm(PersonalInfoFormType.ThirdStep)}
          className="w-1/2 rounded-md border border-solid border-gray-300 bg-white pb-1 pt-1 font-semibold text-gray-900 focus:outline-none"
        >
          Back
        </button>
        <button
          className="focus:shadow-outline ml-4 w-1/2  rounded bg-red-600 font-bold text-white hover:bg-red-700 focus:outline-none sm:mb-0"
          type="submit"
          onClick={() => setCurrentForm(PersonalInfoFormType.FinalStep)}
        >
          Next
        </button>
      </div>
    </div>
  );
}
