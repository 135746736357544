import { useState } from "react";
import classNames from "classnames";

import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { ThirdStep } from "./ThirdStep";
import { FourthStep } from "./FourthStep";
// import { FifthStep } from './FifthStep';

import styles from "./PersonalInfo.module.css";
import { FifthStep } from "./FifthStep";
import { FinalStep } from "./FinalStep";

import { OnboardingFormData } from "../../types";

enum PersonalInfoFormType {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  FinalStep,
}

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

type Props = {
  setCurrentFormProps: (data: FormType) => void;
  setCurrentFormData: (data: Partial<OnboardingFormData>) => void;
  formData: OnboardingFormData;
};

export function PersonalInfo({
  setCurrentFormProps,
  setCurrentFormData,
  formData,
}: Props) {
  const [currentPersonalInfoForm, setCurrentPersonalInfoForm] = useState(
    PersonalInfoFormType.FirstStep,
  );

  const backgroundClass = () => {
    switch (currentPersonalInfoForm) {
      case PersonalInfoFormType.FirstStep:
        return styles.backgroundImage1;
      case PersonalInfoFormType.SecondStep:
        return styles.backgroundImage2;
      case PersonalInfoFormType.ThirdStep:
        return styles.backgroundImage3;
      case PersonalInfoFormType.FourthStep:
        return styles.backgroundImage3;
      case PersonalInfoFormType.FifthStep:
        return styles.backgroundImage4;
      case PersonalInfoFormType.FinalStep:
        return styles.backgroundImage5;
      default:
        return "";
    }
  };

  const renderForm = () => {
    switch (currentPersonalInfoForm) {
      case PersonalInfoFormType.FirstStep:
        return (
          <FirstStep
            setCurrentForm={setCurrentPersonalInfoForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
            setCurrentFormProps={setCurrentFormProps}
          />
        );
      case PersonalInfoFormType.SecondStep:
        return (
          <SecondStep
            setCurrentForm={setCurrentPersonalInfoForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      case PersonalInfoFormType.ThirdStep:
        return (
          <ThirdStep
            setCurrentForm={setCurrentPersonalInfoForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      case PersonalInfoFormType.FourthStep:
        return (
          <FourthStep
            setCurrentForm={setCurrentPersonalInfoForm}
            formData={formData}
            setCurrentFormProps={setCurrentFormProps}
          />
        );
      //   case PersonalInfoFormType.FifthStep:
      //     return (
      //       <FifthStep
      //         setCurrentForm={setCurrentPersonalInfoForm}
      //         formik={formik}
      //         findError={findError}
      //       />
      //     );
      case PersonalInfoFormType.FinalStep:
        return <FinalStep formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div className={backgroundClass()}>
      <div className="flex w-screen flex-col lg:flex-row">
        <div className="flex h-full flex-col justify-center  bg-white  lg:absolute lg:right-0 lg:top-0 lg:w-1/2 lg:rounded-bl-[60px] lg:rounded-tl-[60px]">
          {renderForm()}
        </div>
      </div>
    </div>
  );
}
