import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { Input } from "../../ui/form/Input";

import { OnboardingFormData } from "../../types";

import styles from "./SetupOrganizationProfile.module.css";

import logo from "../../assets/redstone-icon.svg";
import img from "../../assets/bgOrganization.svg";

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

type Props = {
  setCurrentForm: (data: FormType) => void;
  setCurrentFormData: (data: Partial<OnboardingFormData>) => void;
  formData: OnboardingFormData;
};

export function SetupOrganizationProfile({
  setCurrentForm,
  setCurrentFormData,
  formData,
}: Props) {
  const [isFormClear, setIsFormClear] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      website: formData.website || "",
      company_name: formData.company_name || "",
      company_size: formData.company_size || "",
    },
    validationSchema: yup.object({
      website: yup.string().required(),
      company_name: yup.string().required(),
      company_size: yup.string().required(),
    }),
    onSubmit: (data: any) => {
      console.log(data, "Submit data");
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    formik.handleSubmit(event);

    if (isFormClear) {
      setCurrentForm(FormType.PersonalInformation);
    }
  };

  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;

    const isFormFilled = Object.values(formik.values).every(
      (value) => value !== "",
    );

    if (isFormValid && isFormFilled) {
      const updatedValues = Object.fromEntries(
        Object.entries(formik.values).filter(([key, value]) => value !== ""),
      );

      const updatedFormData = { ...formData, ...updatedValues };
      setCurrentFormData(updatedFormData);
      setIsFormClear(true);
    }
  }, [formik.errors, formik.values]);

  function findError(fieldName: keyof typeof formik.initialValues) {
    //@ts-ignore
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? //@ts-ignore
        formik.errors[fieldName]
      : undefined;
  }

  return (
    <div className={styles.backgroundImage}>
      <div className="flex w-screen flex-col lg:flex-row">
        <div className="flex flex-col justify-center bg-white  lg:absolute  lg:right-0 lg:top-0 lg:h-full lg:w-1/2 lg:rounded-bl-[60px] lg:rounded-tl-[60px]">
          <div className="relative flex items-center justify-center">
            <div className="mt-8 flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="flex h-16 w-16 items-center justify-center rounded-full font-semibold">
                  <img src={logo} alt="Redstone" />
                </div>
              </div>
              <h1
                className="mt-8 text-4xl font-semibold text-gray-900"
                style={{ color: "#0A1019" }}
              >
                Setup organization profile
              </h1>
              <form className="mt-8 w-full" onSubmit={handleSubmit}>
                <div className="flex flex-col">
                  <label
                    htmlFor="website"
                    className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Website
                  </label>
                  <Input
                    type="text"
                    placeholder="www.website.com"
                    id="website"
                    name="website"
                    //@ts-ignore
                    error={findError("website")}
                    value={formik.values.website}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="mt-4 flex flex-col sm:mt-0">
                  <label
                    htmlFor="company_name"
                    className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Company Name
                  </label>
                  <Input
                    type="text"
                    placeholder="Enter company name"
                    id="company_name"
                    name="company_name"
                    //@ts-ignore
                    error={findError("company_name")}
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="mt-4 flex flex-col sm:mt-0">
                  <label
                    htmlFor="company_size"
                    className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Company Size
                  </label>
                  <Input
                    type="text"
                    placeholder="Enter company size"
                    id="company_size"
                    name="company_size"
                    //@ts-ignore
                    error={findError("company_size")}
                    value={formik.values.company_size}
                    onChange={formik.handleChange}
                  />
                </div>

                {/* <div className="mt-4 flex flex-col sm:mt-0">
                  <label
                    htmlFor="app_sumo_code"
                    className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    AppSumo Code
                  </label>
                  <Input
                    type="text"
                    placeholder="Enter code"
                    id="appSumoCode"
                    name="app_sumo_code"
                    error={findError('app_sumo_code')}
                    value={formik.values.app_sumo_code}
                    onChange={formik.handleChange}
                  />
                </div> */}

                {/* <button
                  onClick={() => setCurrentForm(FormType.PersonalInformation)}
                  className="mb-4 mt-4 w-full rounded-md border border-solid border-gray-300 bg-white pb-1 pt-1 font-semibold text-gray-900 focus:outline-none"
                >
                  Don't have an AppSumo code?
                </button> */}
                <button
                  className="focus:shadow-outline mb-8 mt-4 w-full rounded bg-red-600 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none sm:mb-0"
                  type="submit"
                >
                  Get Started
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
