import { OnboardingFormData } from "../../../../types";

enum PersonalInfoFormType {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  FinalStep,
}

type Props = {
  formData: OnboardingFormData;
  setCurrentForm: (data: PersonalInfoFormType) => void;
};

export function TableComponent({ formData, setCurrentForm }: Props) {
  return (
    <div className="w-full pb-[138px] sm:pb-0">
      <div className="w-full  text-start">
        <p className="mt-8 text-[18px]">Allowances</p>
      </div>
      <div className="mt-4 w-full border-b border-gray-300 bg-gray-100">
        {(formData.unpaid_leave ||
          formData.paid_holiday ||
          formData.paid_seek_leave_per_year ||
          formData.remote_working_per_week) && (
          <table className="w-full">
            <thead className="bg-gray-200">
              <tr>
                <th className="border-b border-gray-300 px-4 py-2 text-start text-[12px] font-normal">
                  Type
                </th>
                <th className="border-b border-gray-300 px-4 py-2 text-start text-[12px] font-normal">
                  Days
                </th>
              </tr>
            </thead>
            <tbody>
              {formData.unpaid_leave && (
                <tr>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-semibold">
                    Unpaid Leave
                  </td>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-normal">
                    {formData.unpaid_leave}
                  </td>
                </tr>
              )}
              {formData.paid_holiday && (
                <tr>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-semibold">
                    Paid Holiday
                  </td>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-normal">
                    {formData.paid_holiday}
                  </td>
                </tr>
              )}
              {formData.paid_seek_leave_per_year && (
                <tr>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-semibold">
                    Paid Seek Leave Per Year
                  </td>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-normal">
                    {formData.paid_seek_leave_per_year}
                  </td>
                </tr>
              )}
              {formData.remote_working_per_week && (
                <tr>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-semibold">
                    Remote Working Per Week
                  </td>
                  <td className="border-b border-gray-300 px-4 py-2 text-[14px] font-normal">
                    {formData.remote_working_per_week}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      <div className="mb-4 mt-4 flex w-full">
        <button
          onClick={() => setCurrentForm(PersonalInfoFormType.ThirdStep)}
          className="w-1/2 rounded-md border border-solid border-gray-300 bg-white pb-1 pt-1 font-semibold text-gray-900 focus:outline-none"
        >
          Back
        </button>
        <button
          className="focus:shadow-outline ml-4 w-1/2  rounded bg-red-600 font-bold text-white hover:bg-red-700 focus:outline-none sm:mb-0"
          type="submit"
          onClick={() => setCurrentForm(PersonalInfoFormType.FinalStep)}
        >
          Next
        </button>
      </div>
    </div>
  );
}
