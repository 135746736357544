import colors from "tailwindcss/colors";

export const CALENDAR_MODE__MONTH = "Month";
export const CALENDAR_MODE__WEEK = "Week";
export const CALENDAR_MODE__DAY = "Day";

export const EVENT_TYPES: {
  title: string;
  colorName: keyof typeof colors;
  id?: string;
}[] = [
  { title: "Holiday (Paid)", id: "holiday_paid", colorName: "red" },
  { title: "Holiday (Unpaid)", id: "holiday_unpaid", colorName: "yellow" },
  { title: "Working remotely", id: "working_remotely", colorName: "emerald" },
  { title: "Sick leave", id: "sick_leave", colorName: "sky" },
  { title: "Parental leave", id: "parental_leave", colorName: "violet" },
  { title: "Other leave", id: "other_leave", colorName: "pink" },
  { title: "Public holiday", id: "public_holiday", colorName: "violet" },
  { title: "Non-contracted day", id: "non_contracted_day", colorName: "emerald" },
];

export const EVENT_TIME_TYPE__START = "the start of the day";
export const EVENT_TIME_TYPE__MIDDAY = "midday";
export const EVENT_TIME_TYPE__END = "the end of the day";

export const EVENT_START_TIME_TYPES = [
  EVENT_TIME_TYPE__START,
  EVENT_TIME_TYPE__MIDDAY,
];

export const PEOPLE_SEX = [
  { title: "Male", value: "SEX_MALE" },
  { title: "Female", value: "SEX_FEMALE" },
];

export const COUNTRIES = [
  { title: "USA", value: "USA" },
  { title: "Ukraine", value: "UA" },
];

export const EVENT_END_TIME_TYPES = [
  EVENT_TIME_TYPE__END,
  EVENT_TIME_TYPE__MIDDAY,
];

export const ROLE_TYPES = [
  { title: "Software Engineer", value: "SOFTWARE_ENGINEER" },
  { title: "Joint Managing Director", value: "JOINT_MANAGING_DIRECTORY" },
  { title: "Assistant Practice", value: "ASSISTANT_PRACTICE" },
  { title: "IT Technician", value: "IT_TECHNICIAN" },
  { title: "IT Manager", value: "IT_MANAGER" },
  { title: "Office Administrator", value: "OFFICE_ADMINISTRATOR" },
];

export const DEFAULT_TEAM = [
  { title: "Development Team", value: "DEVELOPMENT_TEAM" },
  { title: "Administrator Team", value: "ADMINISTRATOR_TEAM" },
];

export const TIMEZONES = [
  { title: "Eastern Time (USA &amp; Canada) GMT-05:00", value: "ET" },
  { title: "Central Time (USA &amp; Canada) GMT-06:00", value: "CT" },
  { title: "Mountain Time (USA &amp; Canada) GMT-07:00", value: "MT" },
  { title: "Pacific Time (USA &amp; Canada) GMT-08:00", value: "PT" },
];

export const WORKING_OPTIONS = [
  { title: "Full-time", value: "Full-time" },
  { title: "Part-time", value: "Part-time" },
  { title: "Self-employed", value: "Self-employed" },
  { title: "Contract", value: "Contract" },
  { title: "Seasonal", value: "Seasonal" },
];

export const ALLOWANCE = [
  { title: "Unpaid Leave", value: "unpaid_leave" },
  { title: "Paid Holiday", value: "paid_holiday" },
  { title: "Paid Sick Leave Per Year", value: "paid_seek_leave_per_year" },
  { title: "Remote Working Per Week", value: "remote_working_per_week" },
];
