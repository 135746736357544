import { ReactNode } from "react";
import classnames from "classnames";

export type CommonInputProps = {
  error?: string;
};

type BaseInputChildrenProps = {
  className: string;
};

type Props = CommonInputProps & {
  children(props: BaseInputChildrenProps): ReactNode;
};

export function BaseInput({ children, error }: Props) {
  return (
    <div>
      {children({
        className: classnames(
          "block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-sm font-normal text-gray-700 transition ease-in-out focus:outline-none focus:ring-1",
          {
            "focus:border-red-600 focus:ring-red-400": !error,
            "border-rose-600 text-rose-700 ring-rose-400": error,
          },
        ),
      })}
      {error && (
        <div className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
          {error}
        </div>
      )}
    </div>
  );
}
