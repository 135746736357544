import { useState, useEffect } from "react";

import StepIndicator from "../../StepIndicator";
import { ChooseOptionComponent } from "./ChooseOptionComponent";
import { TableComponent } from "./TableComponent";

import { OnboardingFormData } from "../../../types";

import logo from "../../../assets/redstone-icon.svg";

enum PersonalInfoFormType {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  FinalStep,
}

type Props = {
  setCurrentForm: (data: PersonalInfoFormType) => void;
  formData: OnboardingFormData;
  setCurrentFormProps: (data: FormType) => void;
};

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

export function FourthStep({ setCurrentForm, formData }: Props) {
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    if (
      formData.unpaid_leave ||
      formData.paid_holiday ||
      formData.paid_seek_leave_per_year ||
      formData.remote_working_per_week
    ) {
      setShowTable(true);
    }
  }, [formData]);

  return (
    <div className="relative flex items-center justify-center">
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="flex h-16 w-16 items-center justify-center rounded-full font-semibold">
            <img src={logo} alt="Redstone" />
          </div>
        </div>
        <h1
          className="mt-8 text-4xl font-semibold text-gray-900"
          style={{ color: "#0A1019" }}
        >
          Leave Settings
        </h1>

        <p
          className="mt-4 text-center text-base text-gray-700"
          style={{ color: "#555A61", fontSize: "16px" }}
        >
          Please follow these 4 easy steps to create your
          <br /> company profile
        </p>

        <StepIndicator currentStep={3} totalSteps={3} />

        {showTable ? (
          <TableComponent setCurrentForm={setCurrentForm} formData={formData} />
        ) : (
          <ChooseOptionComponent setCurrentForm={setCurrentForm} />
        )}
      </div>
    </div>
  );
}
